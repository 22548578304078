import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Markdown from 'react-markdown';

const ServicePage = (props) => {
  const { data: { markdownRemark: { frontmatter: { title, description, images, subtitle } } } } = props;
  // console.log(other)
  function renderParagraph (props) {
    return <p className={'has-text-left-mobile'}>{props.children}</p>;
  }
  function renderLink (props) {
    return (<a href={props.href} target={'_blank'}>{props.children}</a>);
  }

  return (
    <Layout title={title}>

      <SEO title={title} description={'Kunsttechnologische Untersuchungen. Beratung und Konzepterstellung. Konservierung und Restaurierung. Sammlungs- und Ausstellungsbetreuung.'}/>
      <section className="section pb-0">
        <div className={'columns'}>
          <div className={'column is-11 is-offset-1'}>
            <h1 className="title is-size-5-mobile">{title}</h1>
            <h2 className="subtitle">
              <Markdown source={subtitle} />
            </h2>
          </div>
        </div>
      </section>
      <section className="section pt-0">
        <div className="columns">
          <div className="column is-5 is-offset-1">
            <div className="columns is-multiline">
              {images
                .filter(x => !!x.image && !!x.image.childImageSharp)
                .map(({ image: { id, childImageSharp: { fluid } }, image, title, size }, i) =>
                  <div key={id} className={`column is-${size} `}>
                    <Img
                      fluid={fluid}
                      alt={title}
                      sizes={{ ...fluid, aspectRatio: fluid.aspectRatio }}
                      objectFit="contain"
                      style={{ height: '100%' }}
                      imgStyle={{ height: '100%', objectFit: 'cover' }}/>
                  </div>
                )}
              {images
                .filter(x => !x.image.childImageSharp)
                .map(({ image, title, size }, i) =>
                  <div key={size} className={`column is-${size} `}>
                    <img src={image}
                      alt={title}
                      style={{ width: '100%', objectFit: 'cover' }}
                    />
                  </div>
                )}
            </div>
          </div>
          <div className="column is-5">
            <div className="content is-size-5">
              <Markdown source={description} renderers={{ paragraph: renderParagraph, link: renderLink }}/>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicePage;

export const pageQuery = graphql`
    query ServicePage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            frontmatter {
                title
                subtitle
                description
                images {
                    size
                    text
                    image {
                        id
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                                aspectRatio
                            }
                        }
                    }
                }
            }
        }
    }
`;
